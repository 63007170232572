import exceljs from 'exceljs'

interface ExcelDataHeader {
  name: string
  filterButton: boolean
  totalsRowLabel?: string
  totalsRowFunction?:
    | 'none'
    | 'average'
    | 'countNums'
    | 'count'
    | 'max'
    | 'min'
    | 'sum'
    | 'custom'
}

export enum Orientations {
  landscape = 'landscape',
  portrait = 'portrait'
}

interface ExcelDataOptions {
  name: string
  displayName?: string
  firstCellPosition?: string
  headerRow?: boolean
  totalsRow?: boolean
  exportFileName: string
  fileExtension: 'csv' | 'xlsx'
  csvDelimiter?: ';' | ','
  defaultColWidth?: number,
  orientation?: Orientations,
  worksheetName?: string
}

interface ExcelData {
  headers: Array<ExcelDataHeader>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<Array<any>>
  options: ExcelDataOptions
}

export { ExcelData, ExcelDataHeader, ExcelDataOptions }

export default async function (data: ExcelData) {
  const workbook = new exceljs.Workbook()
  workbook.creator = 'Blue Corner'
  workbook.lastModifiedBy = 'Blue Corner'
  workbook.created = new Date()
  workbook.modified = new Date()
  if (!data.options.worksheetName) {
    data.options.worksheetName = data.options.exportFileName
  }
  if (!data.options.firstCellPosition) {
    data.options.firstCellPosition = 'A1'
  }
  const sheet = workbook.addWorksheet(data.options.worksheetName)
  if (data.options.orientation) {
    sheet.pageSetup.orientation = data.options.orientation
  }
  const opts: exceljs.TableProperties = {
    name: data.options.name,
    ref: data.options.firstCellPosition,
    columns: data.headers,
    rows: data.data
  }
  if ('headerRow' in data.options) {
    opts.headerRow = data.options.headerRow
  }
  if ('totalsRow' in data.options) {
    opts.totalsRow = data.options.totalsRow
  }

  sheet.addTable({
    name: data.options.name,
    ref: data.options.firstCellPosition,
    headerRow: opts.headerRow,
    totalsRow: opts.totalsRow,
    columns: data.headers,
    rows: data.data
  })

  if (data.options.defaultColWidth) {
    for (let i = 0; i < sheet.columns.length; i += 1) {
      let dataMax = 0
      const column = sheet.columns[i]
      for (let j = 1; j < column.values.length; j += 1) {
        const columnLength = column.values[j]?.toString().length
        if (columnLength && columnLength > dataMax) {
          dataMax = columnLength
        }
      }
      column.width = dataMax < data.options.defaultColWidth ? data.options.defaultColWidth : dataMax
    }
  }
  let blob = new Blob([])
  let buff: exceljs.Buffer | undefined
  let filename = ''
  if (data.options.fileExtension === 'xlsx') {
    buff = await workbook.xlsx.writeBuffer()
    filename = data.options.exportFileName + '.xlsx'
  } else if (data.options.fileExtension === 'csv') {
    buff = await workbook.csv.writeBuffer({
      formatterOptions: { delimiter: data.options.csvDelimiter }
    })
    filename = data.options.exportFileName + '.csv'
  }
  if (buff) {
    blob = new Blob([buff], { type: 'data:Application/octet-stream;' })
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      const element = document.createElement('a')
      const url = window.URL.createObjectURL(blob)
      element.setAttribute('href', url)
      element.setAttribute('download', filename)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  }
}
